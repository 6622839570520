import JSZip from "jszip";
import {useState} from "react";

const DownloadChunk = ({icons, title}) => {

    const [isDownloading, setIsDownloading] = useState(false);

    const handleDownload = async () => {
        setIsDownloading(true)

        const zip = new JSZip();
        const img = zip.folder(title);

        const test = getImageChunkBlobs()

        test.forEach((icon) => {
            icon.chunk.forEach((chunk) => {
                 img.file(`${chunk.filename}.png`, chunk.blob);
            })
        })

        const zipFile = await zip.generateAsync({type:"blob"});

        downloadFile(zipFile, 'images.zip');

        setIsDownloading(false)
    }

    const downloadFile = (blob, filename) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();

        window.URL.revokeObjectURL(url);
        a.remove();
    }

    const getImageChunkBlobs = () => {
        return icons.map((icon) => ({
            ...icon,
            chunk: icon.chunk.map((chunk) => ({
                ...chunk,
                blob: fetch(chunk.url).then(async (response) => {
                    return await response.blob();
                })
            }))}
        ));
    }

    return (
        <button className="btn btn-primary" onClick={handleDownload} disabled={!icons?.length || isDownloading}>
            {isDownloading ? 'Téléchargement...' : 'Télécharger mobile chunk'}
        </button>
    )
}

export default DownloadChunk;