import JSZip from "jszip";

const DownloadImages = ({icons, type}) => {

    const handleDownload = () => {
        const zip = new JSZip();
        const img = zip.folder("images");

        Promise.all(getImageBlobs())
            .then(async (blobs) => {
                blobs.forEach((blob, index) => {
                    img.file(`${icons[index].alias}.${type}`, blob);
                });

                const zipFile = await zip.generateAsync({type:"blob"});
                
                downloadFile(zipFile, 'images.zip');
            });
    }

    const downloadFile = (blob, filename) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();

        window.URL.revokeObjectURL(url);
        a.remove();
    }

    const getImageBlobs = () => {
        return icons.map(async (icon) => {
            const response = await fetch(icon.url);
            return await response.blob();
        });
    }

    return (
        <button className="btn btn-primary" onClick={handleDownload} disabled={!icons?.length}>
            Télécharger
        </button>
    )
}

export default DownloadImages;