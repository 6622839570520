import logo from './logo.svg';
import './App.css';
import {Outlet} from "react-router-dom";

function App() {
  return (
      <div className="bg-dark">
          <nav className="navbar navbar-expand-lg bg-body-tertiary">
              <div className="container-fluid">
                  <a className="navbar-brand" href="#">Predict icons</a>
                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                          <li className="nav-item">
                              <a className="nav-link active" aria-current="page" href="inforisque">Inforisque</a>
                          </li>
                          <li className="nav-item">
                              <a className="nav-link active" aria-current="page" href="plan">Plan</a>
                          </li>
                      </ul>
                  </div>
              </div>
          </nav>

          <div style={{maxWidth: 1640}} className="m-auto p-3">
              <Outlet />
          </div>
      </div>
  );
}

export default App;
