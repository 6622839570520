import React, {useEffect} from 'react';
import DownloadImages from "./DownloadImages";
import {planIcon} from "../api/static";
import {useSearchParams} from "react-router-dom";
import {ENVIRONMENT} from "../api/api";

const staticList = [...planIcon.enjeu, ...planIcon.moyens]

const Plan = () => {

    const [listPlan, setListPlan] = React.useState([]);

    const [showAlternative, setShowAlternative] = React.useState(false);
    //get params from window url

    const [selectedEnv, setSelectedEnv] = React.useState('prod');
    const [isDev, setIsDev] = React.useState(false);
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        window.addEventListener('keydown', (e) => {
            if (e.shiftKey && e.altKey && e.key === 'F2') {
                setIsDev(!isDev);
            }
        }, true);
    }, []);

    useEffect(() => {
        if (searchParams.has('size') && !!staticList.length) {
            render(staticList);
        }
    }, [searchParams]);


    const [listParams, setListParams] = React.useState(
        [
            {param: 'size', description: "Taille entre 1 et 500 (inclus)", exemple: "150", value: '64', required: true},
            {param: 'type', description: "Type de retour de l'image", exemple: "jpg ou png", value: 'png', required: false},
            {param: 'selected', description: "Rend le background turquoise", exemple: "true ou false", value: '', required: false},
            {param: 'gray', description: "Rend le background gris", exemple: "true ou false", value: '', required: false},
            {param: 'bg', description: "Couleur hexadécimale du background", exemple: "FF0000", value: '', required: false},
            {param: 'fg', description: "Couleur hexadécimale du foreground", exemple: "00FF00", value: '', required: false},
            {param: 'reverseColors', description: "Inverse le background et le foreground", exemple: "true ou false", value: '', required: false},
            {param: 'has_border', description: "ajoute ou non une bordure", exemple: "true ou false", value: '', required: false},
            {param: 'border', description: "Couleur hexadécimale de la bordure", exemple: "00FF00 ou 'vide' si pas de bordure", value: '', required: false},
            {param: 'border-width', description: "Épaisseur de la bordure", exemple: "5", value: '', required: false},
        ]
    );

    //1C5EA9 contrib color

    useEffect(() => {
        if (searchParams.size) {
            fillListParamsWithSearchParams();
        }

        initWithDefaultParams()

        render(staticList);
    }, [selectedEnv]);

    const buildUrl = (alias, size, params) => {
        const _params = Object.keys(params).map(key => key + '=' + params[key]).join('&');

        return `${ENVIRONMENT[selectedEnv].pictos}picto/plan/${alias}/${size}.png?${_params}`;
    }

    const allPatterns = [
        {fromPcs: true, type: 'INACTIVE', description: 'Inactif'},
        {fromPcs: true, type: 'IN PROGRESS', description: 'En cours'},
        {fromPcs: true, type: 'COMPLETED', description: 'Complété'},
        {fromPcs: true, type: 'BACK TO NORMAL', description: 'Retour à la normale'},
        {fromPcs: true, type: 'FROM SUPERVISOR', description: 'Posé par un superviseur'},
        {fromPcs: false, type: 'IN PROGRESS', description: 'En cours'},
        {fromPcs: false, type: 'COMPLETED', description: 'Complété'},
        {fromPcs: false, type: 'BACK TO NORMAL', description: 'Retour à la normale'},
    ];

    const setAlternateIconParams = ({fromPcs, type, size}) => {
        const borderWidth = 10;
        const params = {};

        if (fromPcs) {
            switch (type) {
                case 'INACTIVE':
                    params['gray'] = 'true';
                    break;
                case 'IN PROGRESS':
                    params['reverseColors'] = 'true';
                    params['has_border'] = 'true';
                    params['border'] = 'de0000';
                    params['border-width'] = (256 / size) * borderWidth;
                    break;
                case 'COMPLETED':
                    // icon de pcs standard
                    break;
                case 'BACK TO NORMAL':
                    params['has_border'] = 'true';
                    params['border'] = '2ecc71';
                    params['border-width'] = (256 / size) * borderWidth;
                    break;
                case 'FROM SUPERVISOR':
                    params['has_border'] = 'true';
                    params['border'] = 'FFF';
                    params['border-width'] = (256 / size) * borderWidth;
                    break;
            }
        } else {
            switch (type) {
                case 'IN PROGRESS':
                    params['bg'] = 'f1c40f';
                    params['fg'] = '000';
                    params['has_border'] = 'true';
                    params['border'] = '000';
                    params['border-width'] = (256 / size) * borderWidth;
                    break;
                case 'COMPLETED':
                    params['bg'] = '000';
                    params['fg'] = 'f1c40f';
                    break;
                case 'BACK TO NORMAL':
                    params['bg'] = '000';
                    params['fg'] = 'f1c40f';
                    params['has_border'] = 'true';
                    params['border'] = '2ecc71';
                    params['border-width'] = (256 / size) * borderWidth;
                    break;
            }
        }

        return params;
    }

    const update = () => {
        const _searchParams = new URLSearchParams(window.location.search);

        listParams.forEach((param) => {
            if (param.value) {
                _searchParams.set(param.param, param.value);
            } else {
                _searchParams.delete(param.param);
            }
        });

        setSearchParams(_searchParams);
    }


    const render = () => {

        let _params = {}

        if (searchParams?.size) {
            _params = Object.fromEntries(searchParams);
        }

        setListPlan(staticList.map((icon) => ({
            ...icon,
            url: buildUrl(icon.alias, _params.size, _params),
            pattern: allPatterns.map((pattern) => ({
                ...pattern,
                url: buildUrl(icon.alias, _params.size, setAlternateIconParams({...pattern, size: _params.size})),
            }))
        })));
    }

    const initWithDefaultParams = () => {
        listParams.filter((param) => !!param?.value?.trim()).forEach((param) => {
            searchParams.set(param.param, param.value);
        });
    }

    const fillListParamsWithSearchParams = () => {
        listParams.forEach((param) => {
            if (searchParams.has(param.param)) {
                param.value = searchParams.get(param.param);
            }
        });
    }


    const updateListParamsValue = (param, value) => {
        setListParams(listParams.map((_param) => {
            if (_param.param === param) {
                _param.value = value;
            }
            return _param;
        }));
    }

    return (
        <div className="d-flex flex-column gap-3">
            <section className="card bg-dark-subtle">
                <div className="card-body">
                    <h3 className="card-title d-flex justify-content-between">
                        Plan
                        <div>
                            <button className="btn btn-sm btn btn-secondary" onClick={() => setShowAlternative(!showAlternative)}>
                                {showAlternative ? 'Afficher les icônes par défaut' : 'Afficher les icônes alternatives'}
                            </button>
                        </div>
                        {isDev && (<DownloadImages icons={listPlan}/>)}
                    </h3>
                    <div className="d-flex flex-wrap gap-2">
                        {
                            listPlan?.map((icon, index) => {
                                return (
                                    <div key={index} className="bg-dark-subtle flex-row border border-1 p-2 rounded border-black" style={{minWidth: showAlternative ? 300 : 150, minHeight: 128}}>
                                        <div>
                                            <div className="d-flex p-2 flex-1 justify-content-center align-content-center">
                                                <img src={icon.url} alt="icon" className="image-highlight"/>
                                            </div>
                                            <div className="bg-dark-subtle text-center border rounded">
                                                <small className="card-title">{icon.alias}</small>
                                            </div>
                                        </div>
                                        {showAlternative && (
                                                <div className="mt-3">
                                                    {
                                                        icon.pattern.map((pattern, index) => {
                                                            return (
                                                                <div key={index} className="d-flex gap-2">
                                                                    <div className="d-flex p-2 flex-1 justify-content-center align-content-center">
                                                                        <img width={32} height={32} src={pattern.url} alt="icon" className="image-highlight"/>
                                                                    </div>
                                                                    <div className="bg-dark-subtle d-flex w-100 p-2 justify-content-center flex-column border rounded">
                                                                        <small><b>État: </b>{pattern.description}</small>
                                                                        {pattern.fromPcs ? (
                                                                            <small>Provient d'un PCS ou d'un PLAN</small>
                                                                        ) : (
                                                                            <small>Créer depuis un GECOS</small>
                                                                        )}

                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>

                                        )}
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </section>

            {isDev && (<>
                <section className="card bg-dark-subtle">
                    <div className="card-body">
                        <h3 className="card-title d-flex justify-content-between">
                            Paramètres

                            <div className="d-flex gap-3">
                                <button className="btn btn-primary" onClick={() => {
                                    update();
                                }}>
                                    update render
                                </button>
                            </div>
                        </h3>
                        <table className="table table-dark table-striped">
                            <thead>
                            <tr>
                                <th scope="col">Param</th>
                                <th scope="col">Description</th>
                                <th scope="col">Exemple</th>
                                <th scope="col">Valeur</th>
                            </tr>
                            </thead>
                            <tbody>
                            {listParams.map((param, index) => (
                                <tr key={index}>
                                    <td>{param.param}</td>
                                    <td>{param.description}</td>
                                    <td>{param.exemple}</td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            required={param.required}
                                            value={param.value}
                                            onChange={(e) => {
                                                updateListParamsValue(param.param, e.target.value);
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                    </div>
                </section>
                        <div className="form-check form-check-inline p-2" style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                        }}>
                            <select className="form-select" onChange={(e) => {
                                setSelectedEnv(e.target.value);
                            }}>
                                <option value="prod">PROD</option>
                                <option value="dev">DEV</option>
                            </select>
                        </div>
                </>
            )}

        </div>
);
}

export default Plan;