

export const planIcon = {
    enjeu: [
        {alias: 'aeroport'},
        {alias: 'agricole'},
        {alias: 'antenne'},
        {alias: 'attention'},
        {alias: 'autres'},
        {alias: 'barrage_retenue'},
        {alias: 'barriere_fixe'},
        {alias: 'bati_administratif'},
        {alias: 'blocage_eau'},
        {alias: 'busage'},
        {alias: 'camping'},
        {alias: 'caserne_pompier'},
        {alias: 'commerce'},
        {alias: 'commercial'},
        {alias: 'decheterie'},
        {alias: 'dechetterie'},
        {alias: 'deneigement'},
        {alias: 'digue'},
        {alias: 'education'},
        {alias: 'elevage'},
        {alias: 'entreprise'},
        {alias: 'equipement_sportif'},
        {alias: 'fermeture_route'},
        {alias: 'gare'},
        {alias: 'gr_habitation'},
        {alias: 'gr_habitation_eau'},
        {alias: 'grue'},
        {alias: 'habitat'},
        {alias: 'habitation'},
        {alias: 'habitation_eau'},
        {alias: 'hebergement'},
        {alias: 'icpe'},
        {alias: 'industriel'},
        {alias: 'loisirs'},
        {alias: 'medical'},
        {alias: 'ouvrage_protection'},
        {alias: 'parc'},
        {alias: 'parking'},
        {alias: 'parking_inondable'},
        {alias: 'parking_souterrain'},
        {alias: 'personne_vulnerable'},
        {alias: 'petite_enfance'},
        {alias: 'plage'},
        {alias: 'police_gendarmerie'},
        {alias: 'pont'},
        {alias: 'port'},
        {alias: 'seveso'},
        {alias: 'social'},
        {alias: 'station_epuration'},
        {alias: 'surveillance'},
        {alias: 'transformateur'},
        {alias: 'transports'},
        {alias: 'travaux'},
        {alias: 'tremie'},
        {alias: 'zone_activite'},
    ],
    moyens: [
        {alias: 'atelier_municipaux'},
        {alias: 'batiment_refuge'},
        {alias: 'centre_accueil'},
        {alias: 'depose_eleve'},
        {alias: 'haut_parleur'},
        {alias: 'heliport'},
        {alias: 'itineraire_evacuation'},
        {alias: 'local_depot_dechets'},
        {alias: 'parking_accueil'},
        {alias: 'patrouille'},
        {alias: 'pc_crise'},
        {alias: 'point_regroupement'},
        {alias: 'point_surveillance'},
        {alias: 'police'},
        {alias: 'pompier'},
        {alias: 'sirene'},
        {alias: 'zone_refuge'},
    ],
}