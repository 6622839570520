import React, {useEffect} from 'react';
import DownloadImages from "./DownloadImages";
import {planIcon} from "../api/static";
import {useSearchParams} from "react-router-dom";
import {ENVIRONMENT, getInforisque} from "../api/api";
import DownloadChunk from "./DownloadChunk";

const Inforisque = () => {

    const [icons, setIcons] = React.useState([]);
    const [staticListInforisque, setStaticListInforisque] = React.useState([]);
    const [showAlternative, setShowAlternative] = React.useState(false);
    const [filterByType, setFilterByType] = React.useState('all');
    const [selectedEnv, setSelectedEnv] = React.useState('prod');
    const [isDev, setIsDev] = React.useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    const filterTypeList = [
        {value: 'all', label: 'Tous'},
        {value: 'nsp', label: 'Nsp'},
        {value: 'inforisque', label: 'Inforisque'},
    ];
    const [listParams, setListParams] = React.useState(
        [
            {param: 'size', description: "Taille entre 1 et 500 (inclus)", exemple: "150", value: '64', required: true},
            {param: 'format', description: "Format d'image", exemple: "png/svg", value: 'png', required: true},
            {param: 'cursor', description: "Affiche ou non la pointe en bas du picto", exemple: "true ou false", value: 'false', required: false},
            {param: 'border', description: "Couleur hexadécimale de la bordure", exemple: "00FF00 ou 'vide' si pas de bordure", value: '', required: false},
            {param: 'border-width', description: "Épaisseur de la bordure", exemple: "5", value: '', required: false},
            {param: 'bg', description: "Couleur hexadécimale du background", exemple: "FF0000", value: '', required: false},
            {param: 'fg', description: "Couleur hexadécimale du foreground", exemple: "00FF00", value: '', required: false},
        ]
    );

    useEffect(() => {
        window.addEventListener('keydown', (e) => {
            if (e.shiftKey && e.altKey && e.key === 'F2') {
                setIsDev(!isDev);
            }
        }, true);
    }, []);

    useEffect(() => {
        if (searchParams.has('size') && !!staticListInforisque.length) {
            render(staticListInforisque);
        }
    }, [searchParams]);

    useEffect(() => {
        getInforisque(selectedEnv).then((data) => {
            setStaticListInforisque(data);

            if (searchParams.size) {
                fillListParamsWithSearchParams();
            }

            initWithDefaultParams()

            render(data);
        });
    }, [selectedEnv]);

    const buildUrl = (alias, size, params) => {
        const _params = Object.keys(params).map(key => key + '=' + params[key]).join('&');

        if (params.format === 'svg') {
            return `${ENVIRONMENT[selectedEnv].pictos}picto/inforisque/${alias}.${params.format}?${_params}`;
        }

        return `${ENVIRONMENT[selectedEnv].pictos}picto/inforisque/${alias}/${size}.${params.format}?${_params}`;
    }

    const allPatterns = [
        {type: 'nsp', alias: 'NSP0', description: "NSP - Pas de risque"},
        {type: 'nsp', alias: 'NSP1', description: "NSP - Soyez vigilant"},
        {type: 'nsp', alias: 'NSP2', description: "NSP - Soyez-Prudent"},
        {type: 'nsp', alias: 'NSP3', description: "NSP - Mettez-vous en sécurité"},
        {type: 'nsp', alias: 'NSP4', description: "NSP - Restez en sécurité"},
        {type: 'nsp', alias: 'NSP5', description: "NSP - Retour à la normale"},
        {type: 'inforisque', alias: 'inforisque', description: 'Inforisque'},
        {type: 'inforisque', alias: 'contrib', description: 'Contribution'},
    ];

    const setAlternateIconParams = ({alias}) => {
        const params = {
            format: 'png'
        };

        switch (alias) {
            case 'NSP0':
                params['cursor'] = 'false';
                params['bg'] = '15A815';
                break;
            case 'NSP1':
                params['cursor'] = 'false';
                params['bg'] = 'F5C400';
                break;
            case 'NSP2':
                params['cursor'] = 'false';
                params['bg'] = 'FF6600';
                break;
            case 'NSP3':
                params['cursor'] = 'false';
                params['bg'] = 'FF0000';
                break;
            case 'NSP4':
                params['cursor'] = 'false';
                params['bg'] = '7b11c2';
                break;
            case 'NSP5':
                params['cursor'] = 'false';
                params['bg'] = '808080';
                break;
            case 'inforisque':
                params['cursor'] = 'true';
                params['bg'] = '430168';
                break;
            case 'contrib':
                params['cursor'] = 'true';
                params['bg'] = '305DA4';

        }

        return params;
    }

    const update = () => {
        const _searchParams = new URLSearchParams(window.location.search);

        listParams.forEach((param) => {
            if (param.value) {
                _searchParams.set(param.param, param.value);
            } else {
                _searchParams.delete(param.param);
            }
        });

        setSearchParams(_searchParams);
    }


    const render = async (list) => {
        let _params = {}

        if (searchParams?.size) {
            _params = Object.fromEntries(searchParams);
        }

        const _icons = list?.map((icon) => ({
            ...icon,
            url: buildUrl(icon.alias, _params.size, _params),
            error: false,
            chunk: [
                {filename: icon.alias, url: buildUrl(icon.alias, _params.size, _params)},
                {filename: icon.alias + '@2x', url: buildUrl(icon.alias, _params.size*2, _params)},
                {filename: icon.alias + '@3x', url: buildUrl(icon.alias, _params.size*3, _params)},
                {filename: icon.alias + '@4x', url: buildUrl(icon.alias, _params.size*4, _params)},
            ],
            pattern: allPatterns.map((pattern) => ({
                ...pattern,
                url: buildUrl(icon.alias, 64, setAlternateIconParams({...pattern})),
            }))
        }));

        setIcons(_icons);
    }

    const initWithDefaultParams = () => {
        listParams.filter((param) => !!param?.value?.trim()).forEach((param) => {
            searchParams.set(param.param, param.value);
        });
    }

    const fillListParamsWithSearchParams = () => {
        listParams.forEach((param) => {
            if (searchParams.has(param.param)) {
                param.value = searchParams.get(param.param);
            }
        });
    }

    const updateListParamsValue = (param, value) => {
        setListParams(listParams.map((_param) => {
            if (_param.param === param) {
                _param.value = value;
            }
            return _param;
        }));
    }

    const filterBy = (icon) => {
        if (filterByType === 'nsp') {
            return icon.nsp
        } else if (filterByType === 'inforisque') {
            return icon.inforisque
        }

        return icons;
    }

    const handleError = (event, icon) => {
        setIcons(icons.map((_icon) => {
            if (_icon.alias === icon.alias) {
                _icon.error = true;
            }
            return _icon;
        }));
    }

    return (
        <div className="d-flex flex-column gap-3">
            <section className="card bg-dark-subtle">
                <div className="card-body">
                    <h3 className="card-title d-flex justify-content-between">
                        Inforisque
                        <div className="d-flex gap-3">
                            <div>
                                <select className="form-select" onChange={(e) => {
                                    setFilterByType(e.target.value)
                                }} aria-label="Default select example">
                                    {filterTypeList.map((type, index) => (
                                        <option key={index} value={type.value}>{type.label}</option>)
                                    )}
                                </select>
                            </div>
                            <button className="btn btn-sm btn btn-secondary" onClick={() => setShowAlternative(!showAlternative)}>
                                {showAlternative ? 'Afficher les icônes par défaut' : 'Afficher les icônes alternatives'}
                            </button>
                            {isDev && (<>
                                <DownloadImages icons={icons?.filter((icon) => filterBy(icon))} type={'svg'}/>
                                <DownloadChunk icons={icons?.filter((icon) => filterBy(icon))} title="inforisque"/>
                            </>)}
                        </div>
                    </h3>
                    <div className="d-flex flex-wrap gap-2">
                        {
                            icons?.filter((icon) => filterBy(icon)).map((icon, index) => {
                                return (
                                    <div key={index} className={`
                                        border border-1 p-2 rounded ${icon.error ? 'bg-danger-subtle border-danger' : 'bg-dark-subtle border-black'} 
                                    `} style={{minWidth: 189, minHeight: 128}}>
                                        <div className="d-flex p-2 flex-1 justify-content-center align-content-center position-relative">
                                            <img onError={(e) => handleError(e, icon)} src={icon.url} alt="icon" className="image-highlight"/>
                                        </div>
                                        <div className="bg-dark-subtle text-center border rounded">
                                            <small className="card-title">{icon.alias}</small>
                                        </div>
                                        <div className="d-flex mt-2 gap-2">
                                            {icon.nsp && <span className=" badge rounded-pill bg-danger">NSP</span>}
                                            {icon.inforisque && <span className=" badge rounded-pill bg-light-subtle">Inforisque</span>}
                                            {icon.igo && <span className=" badge rounded-pill" style={{background: "#3988a9"}}>IGO</span>}
                                        </div>
                                        {showAlternative && (
                                            <div className="mt-3">
                                                {
                                                    icon.pattern.filter((a) => a.type === 'nsp' && icon.nsp).map((pattern, index) => {
                                                        return (
                                                            <div key={index} className="d-flex gap-2">
                                                                <div className="d-flex p-2 flex-1 justify-content-center align-content-center">
                                                                    <img width={32} height={32} src={pattern.url} alt="icon" className="image-highlight"/>
                                                                </div>
                                                                <div className="bg-dark-subtle d-flex w-100 p-2 justify-content-center flex-column border rounded">
                                                                    <small><b>Type</b> : {pattern.description}</small>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }

                                                {
                                                    icon.pattern.filter((a) => a.type !== 'nsp').map((pattern, index) => {
                                                        return (
                                                            <div key={index} className="d-flex gap-2">
                                                                <div className="d-flex p-2 flex-1 justify-content-center align-content-center">
                                                                    <img width={32} height={32} src={pattern.url} alt="icon" className="image-highlight"/>
                                                                </div>
                                                                <div className="bg-dark-subtle d-flex w-100 p-2 justify-content-center flex-column border rounded">
                                                                    <small><b>Type</b> : {pattern.description}</small>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        )}
                                    </div>
                                );
                            })
                        }
                    </div>

                    <div className="alert alert-info mt-3">
                        <h4 className="alert-heading">Légende</h4>
                        <p>
                            <span className="badge rounded-pill bg-danger">NSP</span> : Ce sont les pictos qui vont être disponibles dans l'envoi d'alerte <i>(les flash)</i>
                        </p>
                        <p>
                            <span className="badge rounded-pill bg-light-subtle">Inforisque</span> : Ce sont les pictos utilisé pour les inforisques sur toutes les plateformes (Wiki / MyPredict)
                        </p>
                        <p>
                            <span className="badge rounded-pill" style={{background: "#3988a9"}}>IGO</span> : Ce sont les risques utilisés par IGO pour les différents types de risques via Predict Observer
                        <p>
                        </p>
                            <i>Les autres sont disponible mais il ne sont liés à aucun service spécifique, il peuvent notamment être utilisé pour les pictos des différents risque PCS</i>
                        </p>
                    </div>
                </div>
            </section>

            {isDev && (<>
                    <section className="card bg-dark-subtle">
                        <div className="card-body">
                            <h3 className="card-title d-flex justify-content-between">
                                Paramètres

                                <div className="d-flex gap-3">
                                    <button className="btn btn-primary" onClick={() => {
                                        update();
                                    }}>
                                        update render
                                    </button>
                                </div>
                            </h3>
                            <table className="table table-dark table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">Param</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Exemple</th>
                                    <th scope="col">Valeur</th>
                                </tr>
                                </thead>
                                <tbody>
                                {listParams.map((param, index) => (
                                    <tr key={index}>
                                        <td>{param.param}</td>
                                        <td>{param.description}</td>
                                        <td>{param.exemple}</td>
                                        <td>
                                            <input
                                                type="text"
                                                className="form-control"
                                                required={param.required}
                                                value={param.value}
                                                onChange={(e) => {
                                                    updateListParamsValue(param.param, e.target.value);
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>

                        </div>
                    </section>
                    <div className="form-check form-check-inline p-2" style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                    }}>
                        <select className="form-select" onChange={(e) => {
                            setSelectedEnv(e.target.value);
                        }}>
                            <option value="prod">PROD</option>
                            <option value="dev">DEV</option>
                        </select>
                    </div>
                </>
            )}

        </div>
    );
}

export default Inforisque;