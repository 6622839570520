
export const getInforisque = (env) => {
    return fetch(ENVIRONMENT[env].pictos + 'picto/inforisque/all')
        .then(res => res.json())
        .then(async res => {

            const igoRisque = await fetch('https://igopredict.igo.fr/PredictAnalyzer/WebServices/PlanSettings.aspx?all=1')
                .then(res => res.json())

            const nspRisque = await fetch(ENVIRONMENT[env].niveaux + 'niveaux/static/list_risque')
                .then(res => res.json())

            const inforisque = await fetch(ENVIRONMENT[env].hydrometeo + 'inforisques/risques/get/list')
                .then(res => res.json())

            const aliasUsedByEveryService = Array.from(
                new Set(res.list.concat(igoRisque.list.map((a) => a.alias)).concat(nspRisque.list.map((a) => a.alias)).concat(inforisque.list.map((a) => a.alias)))
            );

            return aliasUsedByEveryService.map((alias) => {
                return {
                    alias: alias,
                    igo: !!igoRisque.list.find((igo) => igo.alias === alias),
                    nsp: !!nspRisque.list.find((nsp) => nsp.alias === alias),
                    inforisque: inforisque.list.find((inforisque) => inforisque.alias === alias)?.id_risque
                }
            })
        })
        .catch(err => console.log(err));
}


export const ENVIRONMENT = {
    dev: {
        "niveaux": "http://niveaux.dev-wiki.predict.blue/",
        "pictos": "http://pictos.dev-wiki.predict.blue/",
        "hydrometeo": "http://hydrometeo.dev-wiki.predict.blue/",
    },
    prod: {
        "niveaux": "https://niveaux.predict.network/",
        "pictos": "https://pictos.predict.network/",
        "hydrometeo": "https://hydrometeo.predict.network/",
    }
}

